@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100vh;
}
.persona-widget__iframe {
  height: calc(100vh - 268px);
  min-height: 600px;
  width: 100%;
  max-width: 400px;
}

@font-face {
  font-family: "Satoshi";
  src: url("/public/fonts/Satoshi-Variable.woff2") format("woff2");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/public/fonts/Satoshi-VariableItalic.woff2") format("woff2"),
    url("/public/fonts/Satoshi-VariableItalic.woff") format("woff");
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}
